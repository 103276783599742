import { useState, useEffect} from "react";
import TextField from '@mui/material/TextField';

export default function AddressSearch({ clearAddressSearchText, setClearNpiSearchText, setAddressSearchText, setSearchResults, setClearAddressSearchText, addressFound, setLat, setLng, setNpiSearchText }) {
    const [input, setInput] = useState("");
    const [helperText, setHelperText] = useState("Enter a street address");


    function handleSubmit(e) {
      e.preventDefault();
      setSearchResults(null);  
      setAddressSearchText(input);
      setClearNpiSearchText(true);
      setNpiSearchText(null);
      setLat(null);
      setLng(null);
    }
  
    function handleChange(e) {

      setInput(e.target.value);
    }


    useEffect(()=>{
      if( clearAddressSearchText){
        setInput("");
        setAddressSearchText(null);
        setClearAddressSearchText(false);
      }
      if( addressFound){
        setHelperText("Enter a street address");
      } else
      {
        setHelperText("Address not found");
      }

    
  }, [clearAddressSearchText, addressFound]); // empty array means only once

    return (
      <div>
        <form onSubmit={handleSubmit}>
        
          <TextField
            error={!addressFound}
            value={input}
            variant="outlined"
            onChange={handleChange}
            placeholder="Enter a street address"
            id="searchText"
            label="Street Address"
            helperText={helperText}
          />
        </form>
      </div>
    );
  }