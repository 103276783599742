import { useState, useEffect } from "react";
import SearchResults from "./SearchResults";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export function CustomBackdrop(props) {
  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}
      open={props.open}
    >
      <CircularProgress color={"inherit"} />
    </Backdrop>
  );
}

export default function Search({
  npiSearchText,
  addressSearchText,
  setOsmSearchResults,
  setSearchResults,
  lat,
  lng,
  range
}) {
  const [apiSearchResults, setApiSearchResults] = useState(null);
  const [addressSearchResults, setAddressSearchResults] = useState(null);
  const [searching, setSearching] = useState(false);
  const count = "3500"; // How many nearby providers to return


  useEffect(() => {
    //if (npiSearchText == null) {
    //  console.log("npiSearchText is null!");
    //}

    let bodyObj = {};
    let isNpi = false;
    let isDirectAddress = false;
    let isStreetAddress = false;
    let isLatitudeSearch = false;

    if (npiSearchText && npiSearchText.trim().length) {
      if (npiSearchText.match(/[^\w]|_/) == null) {
        // Numeric, it's an npi

        bodyObj.provNpi = npiSearchText;
        isNpi = true;
      } else {
        // It's a direct address

        bodyObj.servDirectAddr = npiSearchText;
        isDirectAddress = true;
      }
      
    } 

    if(!npiSearchText && lat &&  lat != "" && lng &&  lng != ""){
      //console.log("latitudeSearch lat=" + lat + " lng=" + lng);
      bodyObj.latitude = lat;
      bodyObj.longitude = lng;
      isLatitudeSearch = true;
    }

    if (addressSearchText && addressSearchText.trim().length) {
      bodyObj.streetAddress = addressSearchText;
      isStreetAddress = true;
    }


    bodyObj.count = count;
    bodyObj.range = range;



    //if ((npiSearchText && (isDirectAddress || isNpi)) || isLatitudeSearch) {
    if ((npiSearchText || isLatitudeSearch) ) {
      setSearching(true);
      fetch(
        "https://aocxx6m69i.execute-api.us-east-2.amazonaws.com/v1/provider-location-search",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "IZ44pNgw2i3maM97akCrG61vPgAwwuWh72gcJewv",
          },
          body: JSON.stringify(bodyObj),
        })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if( data.body != undefined){
            setSearchResults(data);
            setApiSearchResults(data);
          } else {
            console.log("timeout!")
          }

          setSearching(false);
        });
      
      /*
      fetch(
        "https://w4c4ylzii5.execute-api.us-east-2.amazonaws.com/v1/provider-search",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "IZ44pNgw2i3maM97akCrG61vPgAwwuWh72gcJewv",
          },
          body: JSON.stringify(bodyObj),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if( data.body != undefined){
            setSearchResults(data);
            setApiSearchResults(data);
          } else {
            console.log("timeout!")
          }

          setSearching(false);
        });
        */
    }
    if (addressSearchText && isStreetAddress) {
      setSearching(true);
      let query =
        "https://nominatim.openstreetmap.org/search?q=" +
        bodyObj.streetAddress +
        "&format=geojson";
      query = encodeURI(query);


      fetch(query, {
        method: "GET",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setOsmSearchResults(data);
          setAddressSearchResults(data);
          setSearchResults(null);
          setApiSearchResults(null);
          setSearching(false);
        });
    }
  }, [npiSearchText, addressSearchText, lat, lng, range]);

  return (
    <div>
      <SearchResults npiSearchText={npiSearchText} searchResults={apiSearchResults} addressSearchResults={addressSearchResults} />
      <CustomBackdrop open={searching} />
    </div>
  );
}
