import React, { useRef, useEffect, useState } from "react";
import maplibregl from "maplibre-gl";
import "@watergis/maplibre-gl-legend/dist/maplibre-gl-legend.css";

import "maplibre-gl/dist/maplibre-gl.css";
import "./MLMap.css";

/*

  https://docs.maptiler.com/react/maplibre-gl-js/how-to-use-maplibre-gl-js/
  https://visgl.github.io/react-map-gl/docs/get-started

npm start
*/

export default function MLMap({
  lat,
  lng,
  servDirectAddr,
  range,
  specialty,
  servDesc,
  closeProviders,
  setCloseProviders,
  setCloseProviderSpecialties,
  currentMarkers,
  setCurrentMarkers,
  setCloseServDesc
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  //const [providerLocations, setProviderLocations] = useState(null);
  const providerLocations = useRef(null);
  const count = "3500"; // How many providers to return

 
  const [zoom] = useState(13);
  //const [API_KEY] = useState('YLHUognKTQe3hQ3Au6oK'); // eric.mears@directtrust.org
  //const [API_KEY] = useState('1AEYXzQDMoWRR1lGq6bj'); // ericmmears@gmail.com



  /* https://codepen.io/bothness/pen/ExgwzEG */
  const style = {
    version: 8,
    sources: {
      osm: {
        type: "raster",
        tiles: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
        tileSize: 256,
        attribution: "&copy; OpenStreetMap Contributors",
        maxzoom: 19,
      },
    },
    layers: [
      {
        id: "osm",
        type: "raster",
        source: "osm", // This must match the source key above
      },
    ],
  };

  useEffect(() => {
    if (specialty !== undefined && specialty !== null) {
      //console.log("specialty = " + specialty.code);
    }
    if (servDesc !== undefined && servDesc !== null) {
      //console.log("servDesc = " + servDesc.code);
    }

    if (typeof lat == "undefined" || lat == null) {
      //console.log("lat is unusable, bailing...");
      return;
    }
    if (typeof lng == "undefined" || lng == null) {
      //console.log("lng is unusable, bailing...");
      return;
    }

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: style,
      center: [lng, lat],
      zoom: zoom,
    });

    
    if( lat=='' && lng=='')
      return;
    //console.log("plotting " + lat + " " + lng);
    new maplibregl.Marker({ color: "#FF0000" })
      .setLngLat([lng, lat])
      .setPopup(new maplibregl.Popup().setHTML(servDirectAddr))
      .addTo(map.current);

    map.current.addControl(new maplibregl.NavigationControl(), "top-right");
    if( closeProviders){
      providerLocations.current = closeProviders;
    }


      let locations = closeProviders;
      if( closeProviders == undefined){
        locations = providerLocations.current;
      } 
      
      if (locations) {

        currentMarkers.forEach((item) => {
            item.remove();
        })
    
        var locationHashMap = new Map();
    
    
        /* this is the search target marker */
        let marker = {
          color: "#FF0000",
          latitude: lat,
          longitude: lng,
          popupHtml: servDirectAddr,
          count: 1,
          orgs: [],
          target: 1,
        };
        let initial_marker = {
          latitude: lat,
          longitude: lng,
          popupHtml: 1,
          count: 1,
          orgs: [],
        };
        // Load up with the initial marker
        let cluster_precision = 4;
        locationHashMap.set(parseFloat(lat).toFixed(cluster_precision), marker);
    
        locations.forEach((item) => {
          if (specialty !== undefined && specialty !== null) {
            // we have a filter request
            if (item !== null && item.provSpecCode !== specialty.code) {
              return;
            }
          }
          if (servDesc !== undefined && servDesc !== null) {
            if (item !== null && item.servDesc !== servDesc.code) {
              return;
            }
          }
          let mapLat = parseFloat(item.latitude).toFixed(cluster_precision);
          let initial_marker = {
            latitude: item.latitude,
            longitude: item.longitude,
            popupHtml: 1,
            count: 1,
            orgs: [],
          };
          /* let's consolidate addresses in the same location */
          let current_marker = locationHashMap.get(mapLat);
          if (current_marker !== undefined) {
            /* There is already an address here */
            current_marker.count += 1;
            //console.log(item.orgName);
            if (item.orgName === undefined) {
              //console.log(item.orgName);
            }
            if (
              item.orgName !== undefined &&
              item.orgName.length > 0 &&
              !current_marker.orgs.includes(item.orgName) &&
              !current_marker.orgs.includes("<br>" + item.orgName)
            ) {
              /* this is a new organization */
              current_marker.orgs.push("<br>" + item.orgName);
            }
            if (current_marker.orgs.length > 0) {
              /* if we have orgs, log the number of them and the org names */
              current_marker.popupHtml =
                "(" + current_marker.count + ")\n" + current_marker.orgs.toString();
            }
            locationHashMap.set(
              parseFloat(item.latitude).toFixed(cluster_precision),
              current_marker
            );
          } else {
            /* this address doesn't already have a marker */
            if (item.orgName !== undefined && item.orgName.length > 0) {
              /* if there is a org name, save it */
              initial_marker.orgs.push(item.orgName);
              initial_marker.popupHtml =
                "(" + initial_marker.count + ")\n" + initial_marker.orgs.toString();
            }
            locationHashMap.set(
              parseFloat(item.latitude).toFixed(cluster_precision),
              initial_marker
            );
          }
          
        });
    
        //locationHashMap.set(parseFloat(lat).toFixed(2)+parseFloat(lng).toFixed(2), "base location");
    
        locationHashMap.forEach((item) => {
          let markercolor = "#3374ff"; // blue
    
          if (item.count >= 1 && item.count < 10) {
            markercolor = "#3374ff";
          }
          if (item.count >= 10 && item.count < 100) {
            markercolor = "#00FF7F"; // green
          }
          if (item.count >= 100 && item.count < 1000) {
            markercolor = "#B8860B"; // dark goldenrod
          }
          if (item.count >= 1000) {
            markercolor = "#FF00FF"; // magenta
          }
          if (item.target !== undefined) {
            markercolor = "#FF0000"; // red
          }
          var oneMarker= new maplibregl.Marker({ color: markercolor })
            .setLngLat([item.longitude, item.latitude])
            .setPopup(new maplibregl.Popup().setHTML(item.popupHtml))
            .addTo(map.current);
          currentMarkers.push(oneMarker);  
          //console.log(".");
        });
    
      }

  }, [lng, lat, zoom, range, specialty, servDesc, closeProviders]);



    

  if (map.current) {
    map.current.on("load", () => {});
  }

  const legendSpanStyle = {
    borderRadius: "50%",
    display: "inline-block",
    height: "10px",
    marginRight: "5px",
    width: "10px",
  };
  const legendStyle = {
    backgroundColor: "#fff",
    borderRadius: "3px",
    bottom: "30px",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    font: "12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif",
    padding: "10px",
    position: "absolute",
    right: "10px",
    zIndex: 1,
  };

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
      {
        <div
          id="county-legend"
          className="legend"
          style={{ ...legendStyle, display: "inline-block" }}
        >
          <h4>Legend</h4>
          <div>
            <span
              style={{ ...legendSpanStyle, backgroundColor: "#3374ff" }}
            ></span>
            &lt; 10
          </div>
          <div>
            10 &le;&nbsp;
            <span
              style={{ ...legendSpanStyle, backgroundColor: "#00FF7F" }}
            ></span>
            &lt; 100
          </div>
          <div>
            100 &le;&nbsp;
            <span
              style={{ ...legendSpanStyle, backgroundColor: "#B8860B" }}
            ></span>
            &lt; 1000
          </div>
          <div>
            <span
              style={{ ...legendSpanStyle, backgroundColor: "#FF00FF" }}
            ></span>
            &ge; 1000
          </div>
        </div>
      }
    </div>
  );
}
