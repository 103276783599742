import React from 'react';
import Range from './components/Range';
import MLMap from './components/MLMap';
import './App.css';
import { useState, useEffect} from "react";
import Container from '@mui/material/Container';
import { Typography, CssBaseline, AppBar, Toolbar, Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ProviderList from './components/ProviderList';
import Paper from '@mui/material/Paper';
import SpecialtySelect from './components/SpecialtySelect';
import ServDescSelect from './components/ServDescSelect';
import NpiSearchText from './components/NpiSearchText';
import AddressSearch from './components/AddressSearch';
import Search from './components/Search';
import logo from './DirectTrustR_RGB_Logo_horiz.png';


/*
https://docs.google.com/document/d/1IbqEPcLvr_gOh4vEtsVF9zqO10jeGJfUStOVLYAU1K0/edit?tab=t.0
Success! Created provider-search-map at /Users/eric/Documents/ProviderSearch/react/provider-search-map
Inside that directory, you can run several commands:

  npm start
    Starts the development server.

  npm run build
    Bundles the app into static files for production.
    using Express Server
    https://itnext.io/node-express-letsencrypt-generate-a-free-ssl-certificate-and-run-an-https-server-in-5-minutes-a730fbe528ca

  npm test
    Starts the test runner.

  npm run eject
    Removes this tool and copies build dependencies, configuration files
    and scripts into the app directory. If you do this, you can’t go back!

We suggest that you begin by typing:

  cd provider-search-map
  npm start

Happy hacking!


https://mui.com/material-ui/all-components/


*/

function setPosition(position) {
  //console.log("setPosition callback: " + position.coords.latitude + " " + position.coords.longitude);
}
function error(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}


function App() {


  const [publicWebsite, setPublicWebsite] = useState(true);
  const [lng, setLng] = useState("");
  const [lat, setLat] = useState("");
  const [servDirectAddr, setServDirectAddr] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [osmSearchResults, setOsmSearchResults] = useState(null);
  const [range, setRange] = useState("2");
  const [specialty, setSpecialty] = React.useState(null);
  const [servDesc, setServDesc] = React.useState(null);
  const [closeProviderSpecialties, setCloseProviderSpecialties] = React.useState(null);
  const [clearNpiSearchText, setClearNpiSearchText] = useState(false);
  const [clearAddressSearchText, setClearAddressSearchText] = useState(false);
  const [addressSearchText, setAddressSearchText] = useState(null);
  const [npiSearchText, setNpiSearchText] = useState(null);
  const [currentMarkers, setCurrentMarkers] = useState([]);
  const [closeServDesc, setCloseServDesc] = useState([]);
  const [addressFound, setAddressFound] = useState(true);
  const [closeProviders, setCloseProviders] = useState(null);
  

  


  useEffect(() => {


/* geolocation debug start */
if ("geolocation" in navigator) {
  if (navigator.geolocation !== undefined) {
    navigator.geolocation.getCurrentPosition(setPosition, error);
  }
} else {
  console.log("geolocation is NOT available ");
}
/* geolocation debug end */
if ("geolocation" in navigator && lng == '' && lat == '') {
  /* geolocation is available */

  navigator.geolocation.getCurrentPosition((position) => {
    // Only set this if all other search parameters are null
    if( !npiSearchText && !addressSearchText){
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    }

    //console.log("geolocation is available " + position.coords.latitude + " " + position.coords.longitude);
  });

} else {
  if (lng == null && lat == null) {
    /* geolocation IS NOT available */
    console.log("geolocation IS NOT available");
  }
}


    let searchResultsObject = null;
    if (searchResults) {

      searchResultsObject = JSON.parse(searchResults.body);
      let primaryLocationRecord = searchResultsObject.primaryLocationRecord;
      setCloseProviders(searchResultsObject.locationRecords);
      // Pick out the specialties and servDesc
      let closeSpecialties = [];
      let closeServDesc = [];
      searchResultsObject.locationRecords.forEach((item) => {
        if (item.provSpecText !== undefined) {
          let specialty = {
            code: item.provSpecCode,
            display: item.provSpecText,
          };
  
          if (
            !closeSpecialties.some(
              (closeSpecialty) => closeSpecialty["code"] === specialty.code
            )
          ) {
            closeSpecialties.push(specialty);
          }
        }
        if (item.servDesc !== undefined) {
          let servDesc = {
            code: item.servDesc,
            display: item.servDesc,
          };
          //console.log("servDesc=" + servDesc);
          if (
            !closeServDesc.some(
              (closeServDesc) => closeServDesc["code"] === servDesc.code
            )
          ) {
            closeServDesc.push(servDesc);
          }
        }
  
      });
      setCloseProviderSpecialties(closeSpecialties);
      setCloseServDesc(closeServDesc);
  
  
      if (typeof (primaryLocationRecord.latitude) != "undefined" && primaryLocationRecord.latitude != null && typeof (primaryLocationRecord.longitude) != "undefined" && primaryLocationRecord.longitude != null) {
  
        // only do the 90 degree offset if we are getting results from the original provider directory
        //searchResultsObject.longitude = parseFloat(searchResultsObject.longitude) + 90;
        if (lng !== primaryLocationRecord.longitude || lat !== primaryLocationRecord.latitude) {
          console.log("App setting new center to " + primaryLocationRecord.latitude + " " + primaryLocationRecord.longitude);
  
          setLng(primaryLocationRecord.longitude);
          setLat(primaryLocationRecord.latitude);
          setServDirectAddr(primaryLocationRecord.servDirectAddr);
        }
      } else {
        //console.log("searchResultsObject lat long is missing...");
      }
  
    }


    if (osmSearchResults) {
      //console.log("osmSearchResults " + osmSearchResults);
      if (osmSearchResults.features.length > 0) {
        //(osmSearchResults.features.length + " features found.");
        osmSearchResults.features.forEach(feature => {
          //console.log(feature.properties.display_name);
        })
        let coordinates = osmSearchResults.features[0].geometry;
        let osmLat = coordinates.coordinates[1];
        let osmLng = coordinates.coordinates[0];
        if (typeof (osmLat) != "undefined" && osmLat != null && typeof (osmLng) != "undefined" && osmLng != null) {
          if (lng !== osmLng || lat !== osmLat) {
            console.log("App setting new center to " + osmLat + " " + osmLng);
            setLng(osmLng);
            setLat(osmLat);
          }
        }
        
        setAddressFound(true);
      } else {
        //console.log("no features found");
        setAddressFound(false);
      }
      setOsmSearchResults(null);
    }

  }, [searchResults, osmSearchResults]);



  

  

  let xs = 8
  if (publicWebsite) {

    xs = 12
  }

  return (
    <>
      <CssBaseline />
      <AppBar position='relative'>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
          DirectTrust Aggregated Directory Search
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

        <img src={logo} width="20%" height="20%" />
        <Typography variant="body2">This Directory Search service is provided by DirectTrust for validation of Direct addresses published in the DirectTrust Aggregated Directory. To learn more about the Directory, including how to gain full access to the Directory, visit the Directory page of the DirectTrust website at <Link href="https://directtrust.org/what-we-do/directory-services">https://directtrust.org/what-we-do/directory-services.</Link></Typography>
 
        <Paper elevation={3}>

          <Grid container my={4}>

            <Grid xs={4} >
              <Box  p={2} >
                <NpiSearchText searchResults={searchResults} clearNpiSearchText={clearNpiSearchText} setNpiSearchText={setNpiSearchText} setClearAddressSearchText={setClearAddressSearchText}  setSearchResults={setSearchResults} setClearNpiSearchText={setClearNpiSearchText} setAddressFound={setAddressFound} setLat={setLat} setLng={setLng} setAddressSearchText={setAddressSearchText}/>
                </Box>
            </Grid>                                   
            <Grid xs={4} >
              <Box p={2} >
                <AddressSearch clearAddressSearchText={clearAddressSearchText} setClearNpiSearchText={setClearNpiSearchText}  setAddressSearchText={setAddressSearchText} setSearchResults={setSearchResults} setClearAddressSearchText={setClearAddressSearchText} addressFound={addressFound} setLat={setLat} setLng={setLng} setNpiSearchText={setNpiSearchText}/>
                </Box>
            </Grid>
            <Grid xs={4}>
              <Box p={2} >
                <Range range={range} setRange={setRange} />
              </Box>
            </Grid>           
            <Grid xs={4}>
              <Box p={2} >
                <SpecialtySelect setSpecialty={setSpecialty} closeProviderSpecialties={closeProviderSpecialties} />
              </Box>
            </Grid>
            <Grid  xs={4 }>
              <Box p={2} >
                <ServDescSelect setServDesc={setServDesc} closeServDesc={closeServDesc}/>
              </Box>
            </Grid>
            <Grid  xs={12}>
                <Search npiSearchText={npiSearchText} addressSearchText={addressSearchText} setSearchResults={setSearchResults} setOsmSearchResults={setOsmSearchResults}  lat={lat} lng={lng} range={range}/>
              </Grid>
          </Grid>

        </Paper>

        <Grid container direction="row">

          <Grid xs={4}>
            <ProviderList publicWebsite={publicWebsite} closeProviders={closeProviders} specialty={specialty} />
          </Grid>

          <Grid direction="row" xs={xs}>
            <Paper elevation={3}>
              <Box p={2}>
                <MLMap lat={lat} lng={lng} servDirectAddr={servDirectAddr} searchResults={searchResults} osmSearchResults={osmSearchResults} range={range} specialty={specialty} servDesc={servDesc} closeProviders={closeProviders}  setCloseProviderSpecialties={setCloseProviderSpecialties} currentMarkers={currentMarkers} setCurrentMarkers={setCurrentMarkers} setCloseServDesc={setCloseServDesc}/>
                </Box>
            </Paper>
          </Grid>
        </Grid>
        <Box p={2}>
          <Typography variant="body2" align="center">Copyright 2025 by DirectTrust, All Rights Reserved | 1629 K Street NW, Suite 300 | Washington, DC 20006 | <Link href="https://www.DirectTrust.org">www.DirectTrust.org</Link></Typography>
        </Box>
      </Container>
    </>
  );
}

export default App;